import { render, staticRenderFns } from "./ourselves.vue?vue&type=template&id=b50191c4&scoped=true&"
import script from "./ourselves.vue?vue&type=script&lang=js&"
export * from "./ourselves.vue?vue&type=script&lang=js&"
import style0 from "./ourselves.vue?vue&type=style&index=0&id=b50191c4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b50191c4",
  null
  
)

export default component.exports