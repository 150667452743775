<template>
  <div id="box">
    <Header> 关于我们 </Header>
    <div class="box">
      <div class="content">
        <img src="../../../../static/image/logn.png" alt="" />
        <div class="huaxia">华夏数艺</div>
      </div>
      <div>
        <div
          class="agreement"
          @click="agreement(item.index)"
          v-for="item in text"
          :key="item.index"
        >
          <div>{{ item.name }}</div>
          <img src="../../../../static/image/right.png" alt="" />
        </div>
        <!-- <div class="agreement">
          <div>用户授权协议</div>
          <img src="../../../../static/image/right.png" alt="" />
        </div>
        <div class="agreement">
          <div>用户隐私说明</div>
          <img src="../../../../static/image/right.png" alt="" />
        </div> -->
        <!-- <div class="agreement">
          <div>平台使用协议</div>
          <img src="../../../../static/image/right.png" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: [
        {
          name: '用户注册协议',
          index: 0,
        },
        {
          name: '平台使用规则',
          index: 1,
        },
        {
          name: '用户隐私说明',
          index: 2,
        },
      ],
    };
  },
  methods: {
    // 协议
    agreement(e) {
      this.$router.push({ path: '/agreement', query: { index: e } });
    },
  },
};
</script>
<style lang="less" scoped>
#box {
  background-color: #f3f4f8;
  height: 100vh;
}
.content {
  img {
    width: 63px;
    height: 63px;
    margin-top: 31px;
  }
  .huaxia {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
.agreement {
  margin-left: 17px;
  margin-right: 10px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  border-top: 1px solid #f8f8f8;
  img {
    display: flex;
    width: 7px;
    height: 12px;
  }
}

.box {
  margin-top: 20px;
  margin-left: 17px;
  width: 341px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 5px;
}
</style>
